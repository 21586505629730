body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom SaferPlaces css  */

*, *::before, *::after {
  box-sizing: border-box;
}

.back-to-myproj.safer-btn{
  border: 1px solid rgba(0, 255, 0, 0);
  transition: border-color 1s ease-in-out;
  border-radius: 4px;
}

.back-to-myproj.safer-btn:hover{
  border: 3px solid rgba(green, 1);
}

/* .grid-toolbar-container{
  height: 100%;
} */

.safer-grid-item, .safer-grid-item>div{
  height: 100%;
}

.safer-tools{
  padding: 0 !important;
}

.safer-toolbar{
  justify-content: space-between;
  padding: 0 12px;
}

.safer-myProjs{
  padding-top: 112px;
}
/* 
.safer-content .safer-newProj{
  margin-top: 80px;
} */

.safer-content .safer-editProj, .safer-content .safer-newProj{
  padding-top: 124px;
}

.safer-content .safer-newProj{
  padding-top: 106px;
}

.safer-newProj .left-pane{
  padding-left:16px;
}

.safer-newProj .safer-hamburger{
  transform: translateY(-50%) !important;
}

.safer-hamburger{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-wrap: nowrap;
  min-width: 35px;
  background-color: white;
  padding-right: 12px;
  z-index: 100;
}

.safer-searchbox{
  width:250px;
  height: 40px;
}

.back-from-newProj{
  display: flex;
  justify-content: end;
  margin-left: 12px;
}

@media screen and (min-width:1987px) {
  .safer-content .safer-editProj{
    padding-top: 90px;
  }

  /* .safer-content .safer-newProj{
    margin-top: 80px;
  } */
}

@media screen and (max-width:948px) {
  .safer-content .safer-newProj{
    padding-top:156px;
  }


 
}

@media screen and (max-width:2027px) {
  .safer-hamburger{
    align-self: end;
    margin-bottom: 6px;
  }
}

@media screen and (max-width:1568px) {
  .safer-version{
    display: none;
  }

  .safer-searchbox{
    width:150px;
   
  }
}

@media screen and (max-width:1391px) {
  
  .safer-searchbox{
    width:70px;
   
  }
}

/* @media screen and (min-width:2024px) {
  .safer-content{
    margin-top: 124px;
  }
} */